import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, type FC } from 'react';

interface Props {
  alt: string;
  countryCode: string;
  size?: 'byte' | 'kilo' | 'mega' | 'giga';
}

/* eslint-disable @sumup/circuit-ui/prefer-custom-properties */
const Icon = styled.img<Omit<Props, 'countryCode'>>(
  ({ theme, size = 'mega' }) => css`
    display: inline-block;
    vertical-align: middle;
    width: ${theme.iconSizes[size]};
    height: ${theme.iconSizes[size]};
    object-fit: cover;
    position: relative;
  `,
);
/* eslint-enable @sumup/circuit-ui/prefer-custom-properties */

export const FlagIcon: FC<Props> = ({ countryCode, ...rest }) => {
  if (!countryCode) {
    return null;
  }

  const url = `https://circuit.sumup.com/icons/v1/flag_${countryCode.toLowerCase()}_small.svg`;

  return <Icon src={url} {...rest} />;
};

export default memo(
  FlagIcon,
  (prevProps, nextProps) => prevProps.countryCode === nextProps.countryCode,
);
