import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Select } from '@sumup/circuit-ui';

import FlagIconMemoized from './FlagIcon';

export const StyledFlagIcon = styled(FlagIconMemoized)`
  display: block;
  margin: 10px 0 10px 12px;
  pointer-events: none;
  position: absolute;
  z-index: calc(var(--cui-z-index-input) + 1);
`;

export const CountrySelect = styled(Select, {
  shouldForwardProp: (propName) => propName !== 'isFullWidth',
})<{ isFullWidth?: boolean }>(
  ({ isFullWidth }) => css`
    margin-right: var(--cui-spacings-mega);

    ${isFullWidth && 'width: 100%'}
  `,
);

export const StyledDiv = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-top: var(--cui-spacings-mega);
    width: 100%;

    ${theme.mq.mega} {
      justify-content: flex-end;
      margin: 0;
    }
  `,
);
