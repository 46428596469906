import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Anchor } from '@sumup/circuit-ui';
import { Grid } from '@sumup/circuit-ui/legacy';

/**
 * These offset variables should cover most of the cases
 * for the dropdowns to be displayed properly, as we have
 * shop cart widget also fixed to bottom
 */
const DESKTOP_BOTTOM_OFFSET_FOR_PDP_CART_BAR = 82;
const DESKTOP_BOTTOM_OFFSET_FOR_CART_BAR = 76;
const DESKTOP_TOP_OFFSET_FOR_PRODUCTS = 106;
const MOBILE_BOTTOM_OFFSET_FOR_CART = 92;

export const CookiePreferenceContainer = styled.div<{
  isProductSelectionPage?: boolean;
}>(
  ({ theme, isProductSelectionPage }) => css`
    position: relative;
    display: flex;
    align-items: center;

    ${isProductSelectionPage &&
    'border-bottom: var(--cui-border-width-kilo) solid var(--cui-border-normal);'}
    padding: var(--cui-spacings-giga) 0;
    margin: 0 var(--cui-spacings-kilo) 0 0;

    ${theme.mq.mega} {
      border-bottom: none;
      padding: 0;

      div + &:nth-of-type(2) {
        border-left: var(--cui-border-width-kilo) solid var(--cui-border-normal);
      }

      ${isProductSelectionPage
        ? `padding: 0 0 0 ${theme.spacings.kilo};`
        : `margin: 0 0 0 -${theme.spacings.kilo}; border: none;`}
    }

    ${theme.mq.tera} {
      ${!isProductSelectionPage && 'padding: 0;'}
    }
  `,
);

export const StyledFooter = styled.footer<{
  isCartVisible: boolean;
  isInExperiment?: boolean;
  isProductDetailsPage?: boolean;
  isProductSelectionPage?: boolean;
  isReducedPdpExpEnabled?: boolean;
}>(
  ({
    theme,
    isCartVisible,
    isInExperiment = false,
    isProductDetailsPage = false,
    isProductSelectionPage = false,
    isReducedPdpExpEnabled = false,
  }) => css`
    background-color: var(--cui-bg-subtle);
    padding: var(--cui-spacings-mega) var(--cui-spacings-mega)
      ${MOBILE_BOTTOM_OFFSET_FOR_CART}px;
    width: 100%;

    ${!isProductSelectionPage &&
    !isProductDetailsPage &&
    isCartVisible &&
    `
      margin-top: ${DESKTOP_TOP_OFFSET_FOR_PRODUCTS}px;
      display: none;

      ${theme.mq.mega} {
        display: block;
      }
    `}

    ${theme.mq.mega} {
      bottom: 0;
      left: 0;
      padding: var(--cui-spacings-mega) 0;
      width: 100%;

      ${isProductDetailsPage &&
      `margin-bottom: ${DESKTOP_BOTTOM_OFFSET_FOR_PDP_CART_BAR}px;`}

      ${isProductDetailsPage &&
      isReducedPdpExpEnabled &&
      `margin-bottom: ${DESKTOP_BOTTOM_OFFSET_FOR_PDP_CART_BAR}px;
      `}

      ${!isInExperiment &&
      `margin-bottom: ${DESKTOP_BOTTOM_OFFSET_FOR_CART_BAR}px;`}
    }

    ${theme.mq.megaToGiga} {
      padding-left: var(--cui-spacings-mega);
    }

    ${theme.mq.tera} {
      ${isProductDetailsPage &&
      !isReducedPdpExpEnabled &&
      `
      bottom: 0;
      margin-bottom: ${DESKTOP_BOTTOM_OFFSET_FOR_CART_BAR}px;
      position: relative;
      `}
    }
  `,
);

export const StyledGrid = styled(Grid)(
  () =>
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    `,
);

export const StyledCol = styled.div(
  ({ theme }) => css`
    display: block;
    width: 100%;

    ${theme.mq.mega} {
      width: auto;
      display: flex;
      align-items: center;
    }
  `,
);

export const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  color: var(--cui-fg-normal);
  font-size: var(--cui-typography-body-one-font-size);
  line-height: var(--cui-typography-body-one-line-height);
  color: var(--cui-border-accent);

  :hover {
    text-decoration: underline;
  }
`;
