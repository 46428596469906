import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Anchor, Body } from '@sumup/circuit-ui';
import type { FC } from 'react';

import { MERCHANT_ID } from 'shared/infra/cookies';
import { dispatchSkipShopEvent } from 'shared/services/tracker/events';
import { ACTIONS, ENTITIES, TRIGGERS } from 'shared/services/walker/constants';
import { getTaggingData } from 'shared/services/walker/tagging';

export interface Props {
  message: string | undefined;
  actionTitle: string | undefined;
  actionUrl: string | undefined;
  orderId?: string;
}

const Container = styled.div(
  ({ theme }) => css`
    // set z-index to default so the shadows from shop header could show up
    position: relative;
    z-index: var(--cui-z-index-default);
    display: flex;
    align-items: center;
    border-bottom: var(--cui-border-width-kilo) solid var(--cui-border-normal);
    padding: calc(var(--cui-spacings-giga) - var(--cui-spacings-kilo)) 0
      var(--cui-spacings-giga) 0;
    margin: 0 var(--cui-spacings-kilo) 0 0;

    ${theme.mq.megaToGiga} {
      border-bottom: none;
      padding: 0;
      margin-right: var(--cui-spacings-kilo);
      margin-left: calc(var(--cui-spacings-kilo) * -1);
    }

    ${theme.mq.giga} {
      border-bottom: none;
      padding: 0;
      margin-right: var(--cui-spacings-kilo);
      margin-left: calc(var(--cui-spacings-kilo) * -1);
    }
  `,
);

const StyledAnchor = styled(Anchor)`
  font-weight: var(--cui-font-weight-regular);
  text-decoration: none;
  margin-left: var(--cui-spacings-bit);
  font-size: var(--cui-typography-body-one-font-size);
  line-height: var(--cui-typography-body-one-line-height);

  :hover {
    text-decoration: underline;
  }
`;

/**
 * HaveAReaderBar shows under the shop header. It contains a message
 * to indicate that who should pay attention here and an action link
 * to guide the next step.
 */
export const HaveAReaderBar: FC<Props> = ({
  message = 'Already have a reader?',
  actionTitle = 'Sign up',
  actionUrl = '',
  orderId,
}: Props) => (
  <Container data-testid="skip_shop-nav-bar">
    <Body size="one">
      {message}
      <StyledAnchor
        size="one"
        href={actionUrl}
        variant="highlight"
        onClick={() => {
          void dispatchSkipShopEvent({
            orderId,
            merchantCode: localStorage.getItem(MERCHANT_ID),
          });
        }}
        {...getTaggingData({
          action: {
            trigger: TRIGGERS.CLICK,
            action: ACTIONS.CLICKED,
          },
          entity: ENTITIES.BUTTON,
          properties: {
            details: {
              button_description: 'skip_shop',
            },
          },
        })}
      >
        {actionTitle}
      </StyledAnchor>
    </Body>
  </Container>
);
